.c-burger {
    display: inline-block;

    margin: $o-xs (-$g-xs) 0 auto;
    padding: $g-xs;

    height: 35px;
    width: $g;

    transition: {
        timing-function: linear;
        duration: .15s;
        property: opacity,filter;
    }

    cursor: pointer;

    &-line {
        height: $g-xs;
        width: $o;
        background-color: #000;

        transition-duration: .275s;
        transition-delay: 75ms;
        transition-timing-function: cubic-bezier(.68,-.55,.265,1.55);

        &:nth-child(2) {
            margin: $g-xs 0;
        }
    }

    &.is-active {
        position: relative;

        .c-burger-line {
            position: absolute;

            &:first-child {
                transform: translate3d(0,10px,0) rotate(135deg);
            }
            &:nth-child(2) {
                display: none;
            }

            &:last-child {
                transform: translate3d(0,-20px,0) rotate(-135deg);
                top: 35px;
            }
        }
    }
}

// > 992
@include media-breakpoint-up(xl) {
    .c-burger {
        display: none;
    }
} 