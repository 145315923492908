.projects-list {
    display: flex;
    flex-wrap: wrap;
}

.o-project {
    margin-bottom: $g;
    position: relative;

    @include size-project;

    &:hover {
        z-index: 5;
    }

    .c-project__title,
    .c-donated {
        padding: 0 $g-sm;
    }
}

.c-project {

    &__img {
        overflow: hidden;

        img {
            transition: $transition;
        }
    }

    &__title {
        transform: translateY(-.7em);
        font-size: 2.4rem;
        text-transform: uppercase;
        margin-bottom: 0;
    
    }

    &__link {
        @extend .u-color-hover;
        display: block;

        &:hover {
            .c-project__img img {
                transform: scale(1.1);
            }
        }
    }

    &__support {

        padding: 0 $g-sm;
        margin-top: $o-sm;

        .btn {
            width: 100%;
        }
        
        a.btn {
            color: #000;
        }
    }
}

// projects
.projects {
    .h1 {
        font: {
            size: 2rem;
            weight: 300;
        };
        margin-bottom: $o;
        max-width: 990px;
        text-transform: inherit;
    }
}

.more-projects {

    @extend .u-color-hover;
    @include size-project;

    &__wrap {
        padding: $g;
        border: 1px solid $grey-color;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        .c-seo {
            margin-bottom: 0;
            span {
                border-bottom: 1px dotted #000;
            }
        }
    }

    &__img {
        width: 90px;
        margin-bottom: $g;
        margin-right: auto;     
    }
}

@include media-breakpoint-up(sm) {

    .projects-list {
        margin: 0 (-$o-xs);
        justify-content: flex-start;
    }

    .o-project {
        display: flex;
        flex-direction: column;
        
        .c-donate {
            margin-top: auto;
        }
    }
}

@include media-breakpoint-up(md) {

    .o-project {

        &::before {
            bottom: -81px;
        }
    }

    .projects-list {
        margin: 0 (-$o-sm);
    }

    .c-project {
        &__title {
            font-size: 2.6rem;
        }
    }

    // projects
    .projects {
        .h1 {
            font-size: 2.4rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    
    .o-project {
        &::before {
            bottom: -88px;
        }
    }

    // projects
    .projects {
        .h1 {
            font-size: 3rem;
        }
    }

    .more-projects__wrap {
        height: calc(100% - 40px);
    }

}

@include media-breakpoint-up(xl) {

    .o-project {
        .c-donated {
            z-index: 1;
            position: relative;
        }

        &::before {
            content: '';
            
            display: none;
            
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: -98px;
            
            border: 1px solid #000;
            background-color: $white;

            &:hover {
                display: block;

                .c-project__support {
                    opacity: 1;
                    top: 100%;
                }
                
                .c-project__link,
                .c-donated {
                    position: relative;
                    z-index: 1;
                }
            }
        }
        
        &:hover {
            .c-project__link,
            .c-donated {
                position: relative;
                z-index: 1;
            }
            
            .btn {
                z-index: 5;
            }
            
            &::before {
                display: block;
            }
            .c-project__support {
                opacity: 1;
                top: 100%;
            }
        }
    }

    .projects-list {
        margin: 0 (-$g-sm);
    }

    .c-project {
        &__title {
            font-size: 3rem;
        }

        &__support {
            margin-top: 0;
            opacity: 0;

            position: absolute;
            top: 98%;
            left: $g-sm;
            right: $g-sm;

            transition: $transition;
        }
    }

    // projects
    .projects {
        .h1 {
            font-size: 3.6rem;
        }
    }
}

@include media-breakpoint-up(hd) {

    .c-project {
        &__title {
            font-size: 3.2rem;
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-project {
        &__title {
            font-size: 3.4rem;
        }
    }

}