.privacy-policy {
    margin-bottom: $o-lg;
    
    &__description {
        margin-bottom: $o-lg;
    }

    .c-seo {
        max-width: 800px;

        h2 {
            text-transform: uppercase;
        }
    }
}

@include media-breakpoint-up(md) {
    .privacy-policy {
        &__description {
            margin-bottom: $o * 3;
        }
    }
    
}

@include media-breakpoint-up(hd)  {
    .privacy-policy {
        .c-seo {
            max-width: 900px;
        }
    }
}