.tabs {
    margin-bottom: 25px;
    padding-bottom: $o-sm;
    position: relative;
    overflow-x: auto;

    .nav {
        flex-wrap: nowrap;

        &-tabs {
            border: none;
        }

        &-item {
            margin-bottom: 0;
            flex-shrink: 0;
        }

        &-link {
            padding: 0;
            border: none;

            @extend .u-color-hover;
            
            &.active {
                color: #000;
            }
        }
    }
}

.projects {
    .tabs {
        margin-bottom: $o;

        .nav {
    
            &-item {
    
                &:not(:last-child) {
                    margin-right: $o;
                }
            }
    
            &-link {
                border-bottom: 1px dotted #000;
                font-size: 2rem;
                opacity: 1;
                @extend .u-color-hover;
                
                &.active {
                    border: none;
                }
                
                &:hover {
                    border-bottom-color: 1px dotted $green !important;
                }
            }
        }
    }
}

.project-info {
    .tabs {
        .nav {
            &-item {
                border-bottom: 1px solid $grey-color;

                &:not(:last-child) {
                    padding-right: $g-sm;
                }
            }

            &-link {
                text-transform: uppercase;
                padding-bottom: $o-xs;
                
                &.active {
                    border-bottom: 2px solid #000; 
                }
            }
        }
    }
}