.c-seo {
    font-size: 1.6rem;
    margin-bottom: $o;

    &:last-child {
        padding-bottom: 0;
    }

    h2 {
        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: $g-sm;
    }

    p {
        margin-bottom: $g-sm;
    }

    p:last-child {
        margin-bottom: 0;
    }
    
    a {
        text-decoration: none;
        border-bottom: 2px solid #000;

        @extend .u-color-hover;

        &:hover {
            border-color: $green;
        }
    }

    blockquote {
        margin-top: $o;
        padding-top: 50px;
        position: relative;

        font-size: 1.8rem;

        &::before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            width: 55px;
            height: 40px;

            background: url(../img/svg/blockquote.svg) center no-repeat;
        }
    }

    ol,ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: $g;

        li {
            padding-left: $g;

            &:not(:last-child) {
                margin-bottom: $g-xs;
            }

            &::before {
                display: inline-block;

                transform: translateX(-15px);
            }
        }
    }

    ul {
        li {
            &::before {
                content: '';

                width: 10px;
                height: 10px;
            
                background-color: $green;
                border-radius: 50%;

                margin-left: -$o-xs;
            }
        }
    }
    ol {
        counter-reset: list;
        li {
            &::before {
                content: counter(list) ". ";
                counter-increment: list;

                position: absolute;
                transform: translateX(-25px);
                
                font-size: inherit;
            }
        }
    }

    .autor {
        margin-bottom: $o;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & > div:first-child {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: $o-xs;
            margin-right: $o-sm;
        }

        h3 {
            margin-bottom: 0;
        }

        span {
            color: $grey-color;
            font-size: 1.2rem;
        }
    }

    &.description {
        p {
            font-size: 2rem;
            line-height: 1.3;
        }

        &--sm {
            p {
                @include font-26;
            }
        }
    }

    &.md {
        p {
            font-size: 1.6rem;
        }
    }
}

@include media-breakpoint-up(md) {

    .c-seo {
        margin-bottom: $g;

        h2 {
            font-size: 2.2rem;
        }

        &.description {
            p {
                font-size: 2.4rem;
            }
        }

        &.md {
            p {
                font-size: 2rem;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-seo {
        max-width: 945px;

        h2 {
            font-size: 2.4rem;
        }

        &.description {
            p {
                font-size: 2.8rem;
            }
        }

        &.md {
            p {
                font-size: 2.4rem;
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-seo {
        h2 {
            font-size: 2.6rem;
        }

        &.description {
            p {
                font-size: 3.2rem;
            }
        }
    }

}

@include media-breakpoint-up(hd) {

    .c-seo {
        h2 {
            font-size: 3.2rem;
        }

        &.description {
            p {
                font-size: 3.6rem;
            }
        }
    }

}

@include media-breakpoint-up(laptop) {
    .c-seo {
        h2 {
            font-size: 3.6rem;
        }
    }
}
