.project-build {
    margin-bottom: $g;
    
    & + .pagination-nav {
        max-width: 730px;
    }
    
    &__list {
        max-width: 730px;
    }
    
    &__icons {
        margin: $o-xs 0;

        svg {
            height: $o-sm;
            margin-right: $g-xs;
        }

        &--photo {
            display: inline-block;
            margin-right: $o-sm;
        }
    }

    &__link {
        @extend .u-color-hover;
        @include font-26;
    }
    
    &__title {
        @include font-26;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: $o;
        }
    }
}

.come-back-link + .project-build__item {
    margin-top: $o-sm;
}

@include media-breakpoint-up(md) {

    .project-build {
        margin-bottom: $o-lg;
    }
}

@include media-breakpoint-up(lg) {

    .project-build {
        margin-bottom: $g-lg;
        &__icons {
            svg {
                height: $g-sm;
            }
        }
    }

}