.weather {
    display: flex;
    justify-content: space-between;

    &-block {
        max-width: 115px;
    }

    &-inner {
        display: flex;
        align-items: flex-start;
    }

    &-day {
        color: $grey-color;
        font-size: 1.3rem;
        text-align: left;
        margin-bottom: 8px;
    }

    &-icon {
        max-width: 35px;
        min-width: 35px;
    }

    &-info {
        padding-left: $g-xs;
    }

    &-temperature {
        display: block;
        width: 100%;
        font: {
            size: 1.6rem;
            weight: 500;
        };
        line-height: 1;
        margin-bottom: 5px;

        -webkit-transform: translateX(-5px);
        transform: translateX(-1px);

    }
    
    &-forecast {
        display: block;
        font: {
            size: 1.1rem;
            weight: 500;
        }
    }
}