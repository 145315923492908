.c-donate {

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    &__item {
        padding: $o $g;
        border: 1px solid $grey-color;

        display: flex;
        flex-direction: column;

        margin-bottom: $o;

        &.disabled {
            .c-donate {
                &-btn {
                    background-color: $grey-color;
                    pointer-events: none;
                }
            }
        }
    }

    &__limit {
        margin-bottom: $g-sm;
        
        span {
            display: inline-block;
            
            color: $white;
            font-size: 1.3rem;
            
            padding: 7px $o-xs;
            background-color: #000;
        }
    }

    &__price {
        font-size: 2rem;

        &-choose {
            margin-bottom: $o-xs;
            
            .form-control {
                @include is-ready;
                @include input-price;

                .is-ready & {
                    background-color: transparent;
                }
            }

        }
    }

    &__count {
        color: $grey-color;
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    &__description {
        font-size: 1.6rem;
        margin: $o-sm 0 $g-sm;
    }

    .btn {
        width: 100%;
        font-size: 1.6rem;
        margin-top: auto;

        @include is-ready;

    }

}

.last-donate {
    padding: $o;
    border: 1px solid $grey-color;
    max-width: 400px;
    margin: 0 auto $g;
    
    &__price,
    &__link,
    .h4 {
        text-transform: uppercase;
        font: {
            size: 1.6rem;
            weight: 500;
        }
    }

    .h4 {
        margin-bottom: $o;
    }

    &__link {
        margin-bottom: 0;
        display: block;
        @extend .u-color-hover;
    }

    &__item:not(:last-child) {
        margin-bottom: $o-sm;
    }

    &__price {
        font-weight: 500;
    }

    .date {
        font-size: 1.1rem;
        color: $grey-color;
    }
}

@include media-breakpoint-only(md) {

    .c-donate {

        &__list {
            flex-direction: row;
        }

        &__item {
            width: calc(50% - 15px);

            &:nth-child(even) {
                margin-left: $o-sm;
            }
            
            &:nth-child(odd) {
                margin-right: $o-sm;
            }
        }
    }

}

@include media-breakpoint-down(lg) {
    .c-donate__item {
        max-width: 340px;
    }
}

@include media-breakpoint-up(lg) {

    .c-donate {

        &__item {
            margin-bottom: $g;
        }
    }

}