.c-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-wrap: wrap;

    background-color: $white;

    transition: $transition;
    z-index: 10;
    
    &-logo {

        a {
            display: block;

            img {
                max-height: 100%;
            }
        }
    }

    &-container {
        width: 100%;
        padding: 0 $g-sm $g-sm;
    }

    &-lang {
        &__link {
            text-decoration: none;
            font-size: 1.6rem;
            text-transform: lowercase;
            padding: $o-xs;
            color: #000;
            @extend .u-color-hover;
        }
    }

}

// < 1200
@include media-breakpoint-down(lg) {

    .c-banner {
        overflow: hidden;
        height: $o-lg;
        max-height: $o-lg;
        padding: 0 $g;


        .show-menu & {
            height: 100vh;
            height: calc(var(--vh,1vh)*100);
            max-height: calc(var(--vh,1vh)*100);
        }

        &-logo {
            margin-left: -$o-xs;
            transition: $transition;
            
            .show-menu & {
                position: absolute;
                left: 50%;
                top: $g-lg;
                transform: translateX(-50%) scale(2.4);
                margin-left: 0;
            }
            
            a {
                height: 60px;
                padding: $o-xs;
            }
        }

        &-lang {
            &__link {
                font-size: 2.2rem;
            }
        }

        &-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 60px;

            text-align: center;

            max-height: calc(100vh - 140px);
            
            overflow-y: auto;
            transition: $transition; 

            .show-menu & {
                transform: translateY(140px);
            }
        }
    }

}

@include media-breakpoint-down(md)  {

    .c-banner {
        padding: 0 $o;
    }

}

// < 768
@include media-breakpoint-down(sm) {

    .c-banner {
        padding: 0 $g-sm;

        &-logo {
            .show-menu & {
                top: $o-lg;
                transform: translateX(-50%) scale(2);
            }
        }

        &-lang {
            &__link {
                font-size: 2rem;
            }
        }

        &-container {
            .show-menu & {
                transform: translateY(100px);
            }
        }
    }

}

// < 576
@include media-breakpoint-down(xs) {
    
    .c-banner {

        &-logo {
            .show-menu & {
                top: $g;
                transform: translateX(-50%) scale(1.8);
            }
        }

        &-lang {
            &__link {
                font-size: 1.6rem;
            }
        }

        &-container {
            .show-menu & {
                transform: translateY(65px);
            }
        }
    }

}

// without mobile
// > 1200
@include media-breakpoint-up(xl) {
    
    .c-banner {
        align-items: center;
        margin: 0 auto;
        padding: $o-xs $g $o-xs 0;
        
        &-logo {
            background-color: $white;
            position: absolute;
            left: 0;
            top: 0;
            
            width: 130px;
            height: auto;

            will-change: transform;
            
            a {
                padding: $g-sm $o 35px;
            }
        }
        
        &-container {
            display: flex;
            align-items: center;
            padding: 0;
        }
        
        &-lang {
            &__link {
                display: inline-block;
            }
        }
        
        .c-social-item {
            margin: 0;
            
            &:not(:last-child) {
                margin-right: $g-xs;
            }
        }
    }
    
}

@include media-breakpoint-up(hd) {

    .c-banner {
        max-width: 1920px;

        &-lang {
            margin-right: -$o-xs;
        }
    }

}