.form {
    &-group {
        margin-bottom: $o-xs;
        position: relative;

        &:nth-last-child(2) {
            margin-bottom: $o;
        }

        .c-support__action & {
            margin: 0 !important;
        }
    }

    &-control {
        height: auto;
        width: 85%;
        max-width: 480px;

        font-size: 1.8rem;
        
        appearance: none;
        border: none;
        box-shadow: none;
        outline: none;

        &:focus {
            box-shadow: none;
            outline: none;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 $g-lg $white inset !important;
            
            &.is-fill {
                -webkit-box-shadow: 0 0 0 $g-lg $white inset !important;

                &:-webkit-autofill:focus,
                &:-webkit-autofill:active,
                &:-webkit-autofill:hover {
                    -webkit-box-shadow: 0 0 0 $g-lg $white inset !important;
                }
            }
        }

        &--lg {
            width: 100%;
            max-width: 730px;
        }
        
        &[type=number] {
            &::-webkit-outer-spin-button,
            &::-webkit-outer-spin-button {
                appearance: none;
                margin: 0; 
            }
        }

        &:not([type=radio]):not([type=checkbox]) {
            padding: $g-sm $g-sm $o-xs;
            border: 1px solid $gray-400;
            color: $gray-600;
            
            &:focus {
                color: $black;
                border-color: $black;
            }

            & + label {
                position: absolute;
                top: 17px;
                left: 20px;

                color: #808080;
                font-size: 1.6rem;
                margin-bottom: 0;

                pointer-events: none;
                transition: $transition;
            }
            
            &:focus,
            &.no-empty {
                & + label {
                    top: 4px;
                    font-size: 1.3rem;
                }
    
            }
            
            &.is-invalid {
                border-color: $red;
            }

            .c-support__action & {
                color: $white;
                padding: $o-sm $g-sm;

                &:focus {
                    color: $white;
                    border-color: $primary;

                    & + label {
                        font-size: 1.8rem;
                    }
                }

                & + label {
                    position: static;
                    color: $white;
                    font-size: 1.8rem;
                }
            }
        }

        &[type=checkbox] {
            display: none;

            & + label {
                position: relative;
                margin: 0;
                padding-left: $o;

                font-size: 1.6rem;
                cursor: pointer;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    will-change: transform;
                    transition: $transition;  
                }

                &::before {
                    top: 2px;
                    left: 0;

                    width: $g-sm;
                    height: $g-sm;
                    border: 1px solid $gray-400;
                    border-radius: 3px;
                }

                &::after {
                    top: 7px;
                    left: $g-xs;

                    width: $o-xs;
                    height: $o-xs;
                    border-radius: 2px;
                    background: $primary;
                    opacity: 0;
                    transform: scale(.5);
                }

                a {
                    border-bottom: 1px solid $black;
                    @extend .u-color-hover;
                }
            }

            &:checked + label {
                &::before {
                    border-color: $primary;
                    color: $primary;
                }

                &::after {
                    transform: scale(1);
                    opacity: 1;
                }
            }
            
            &:not(:checked) + label:hover {
                &::before {
                    border-color: $primary;
                }
            }
        }

    }

    @include media-breakpoint-up(md) {
        &-group {
            margin-bottom: $g-sm;

            &:nth-last-child(2) {
                margin-bottom: $g;
            }
        }

        &-control {
            font-size: 2.4rem;

            &:not([type=radio]):not([type=checkbox]) {
                padding: $o $g $o-xs;

                & + label {
                    font-size: 2.4rem;
                    top: $g-sm;
                    left: $g;
                }

                &:focus,
                &.no-empty {
                    & + label {
                        top: $o-xs;
                        font-size: 1.6rem;
                    }
                }
            }

            &[type=checkbox] {
                display: none;
    
                & + label {
                    font-size: 1.8rem;
                    padding-left: $g;
               
                    &::before {
                        width: 26px;
                        height: 26px;
                    }
    
                    &::after {
                        top: 8px;
                        left: 6px;
                        width: 14px;
                        height: 14px;
                    }
                }

            }
        }
    }
}

.invalid-feedback {
    color: #ED3C3C;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
    margin: 0;
}

textarea {
    min-height: $g * 3;
}