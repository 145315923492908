// @font-face {
//     font-family: 'Formular';
//     src: url('../fonts/formular/Formular-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/formular/Formular-Light.otf')  format('opentype'),
//         url('../fonts/formular/Formular-Light.woff') format('woff'),
//         url('../fonts/formular/Formular-Light.ttf')  format('truetype'),
//         url('../fonts/formular/Formular-Light.svg#Formular-Light') format('svg');
//     font-weight: 300;
// };

@font-face {
    font-family: 'Formular';
    src: url('../fonts/formular/Formular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/formular/Formular.otf')  format('opentype'),
        url('../fonts/formular/Formular.woff') format('woff'),
        url('../fonts/formular/Formular.ttf')  format('truetype'),
        url('../fonts/formular/Formular.svg#Formular') format('svg');
    font-weight: 400;
};

@font-face {
    font-family: 'Formular';
    src: url('../fonts/formular/Formular-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/formular/Formular-Medium.otf')  format('opentype'),
        url('../fonts/formular/Formular-Medium.woff') format('woff'),
        url('../fonts/formular/Formular-Medium.ttf')  format('truetype'),
        url('../fonts/formular/Formular-Medium.svg#Formular-Medium') format('svg');
    font-weight: 500;
};

@font-face {
    font-family: 'Formular';
    src: url('../fonts/formular/Formular-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/formular/Formular-Bold.otf')  format('opentype'),
        url('../fonts/formular/Formular-Bold.woff') format('woff'),
        url('../fonts/formular/Formular-Bold.ttf')  format('truetype'),
        url('../fonts/formular/Formular-Bold.svg#Formular-Bold') format('svg');
    font-weight: 700;
};

.fz-64 {
    @include fluidFontSize(24px, 64px, 480px, 1660px, 42px);
}