.o-slider {
    margin: $o 0;

    .o-project::before {
        display: none;
    }

    &__project {
        position: relative;

        &-container {
            .pagination-slider {
                position: absolute;
                bottom: $o-xs !important;
                left: $o-xs !important;
                
                z-index: 1;
            }

            .swiper-slide {
                height: auto;
                max-height: 500px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .btn-arrow {
            position: absolute;
            top: 50%;

            width: 44px;
            height: 44px;

            transform: translateY(-50%);
            
            background-color: #000;

            display: flex;
            justify-content: center;
            align-items: center;
            
            cursor: pointer;

            z-index: 1;

            &.next {
                right: -$o-xs;
            }
            &.prev {
                left: -$o-xs;
                transform: translateY(-50%) rotate(180deg);
            }

            &::before,
            &::after {
                content: '';

                position: absolute;
                left: 50%;

                display: inline-block;
                background-color: $white;

                height: 3px;
                will-change: transform;
            }

            &::before {
                width: 17px;
                top: 15px;
                transform: translateX(-37%) rotate(45deg);
            }
            
            &::after {
                width: 14px;
                bottom: 14px;
                transform: translateX(-50%) rotate(-45deg);
            }
            
            &:hover {
                &::before {
                    top: 14px;
                }
                
                &::after {
                    bottom: 13px;
                }

            }

            &.swiper-button-disabled:hover {
                &::before {
                    top: 15px;
                }
                
                &::after {
                    bottom: 14px;
                }
            }
        }
    }
    
    .h2 {
        text-transform: uppercase;
        margin-bottom: $g-sm;
        font-size: 2rem;
    }

    &__wrapper {
        padding-left: $g-sm;
        position: relative;

        .btn-arrow {
            position: absolute;
            top: 25%;

            &-line {
                &--1,
                &--2,
                &--3 {
                    background-color: #000;
                }
            }
        }
    }

    &__projects {
        margin: 0 (-$g-sm);

        .o-project {
            padding: 0;

            &.swiper-slide {
                height: auto;
            }
        }
    }

}


@include media-breakpoint-up(md) {
    
    .o-slider {
        margin: $g 0;

        .h2 {
            margin-bottom: $o;
            font-size: 2.4rem;
        }
    
        &__wrapper {
            padding-left: $o;
        }

        &__project {
            &-container {
                .pagination-slider {
                    bottom: $g-sm !important;
                    left: $g-sm !important;
                    
                }
            }
        }
    
        &__projects {
            margin: 0 (-$o);
        }
    }

}

@include media-breakpoint-up(lg) {
    
    .o-slider {
    
        &__wrapper {
            padding-left: $g;
        }
    
        &__projects {
            margin: 0 (-$g);
        }
    }

}

@include media-breakpoint-up(xl) {
    
    .o-slider {

        &__wrapper {
            padding-left: $o-lg;
        }
    
        &__projects {
            margin: 0 (-$o-lg);
        }
    }

}

@include media-breakpoint-up(hd) {
    
    .o-slider {

        .h2 {
            margin-bottom: $g;
            font-size: 3rem;
        }

        &__wrapper {
            padding-left: $g-lg;

            .btn-arrow {
                &.prev {
                    left: $g-lg;
                }

                &.next {
                    right: $g-lg;
                }
            }
        }
    
        &__projects {
            margin: 0 (-$g-lg);
        }
    }

}

@include media-breakpoint-up(fullhd) {
    
    .o-slider {
        &__projects {
            margin: 0 (-$g) 0 (-$g-lg);
        }
    }

}