@mixin margin-out {
    margin-left: -$g-sm;
    margin-right: -$g-sm;
    
    @include media-breakpoint-up(md) {
        & {
            margin-left: -$o;
            margin-right: -$o;
        }
    }
    
    @include media-breakpoint-up(lg) {
        & {
            margin-left: -$g;
            margin-right: 0;
        }
    }
    
    @include media-breakpoint-up(xl) {
        & {
            margin-left: -$o-lg;
        }
    }
    
    @include media-breakpoint-up(hd) {
        & {
            margin-left: -$g-lg;
        }
    }
}

@mixin size-project {

    @include media-breakpoint-only(xs) {
        & {
            width: 100%;
            max-width: 400px;
        }
    }
    
    @include media-breakpoint-up(sm) {
        & {
            width: 50%;
            padding: 0 $o-xs;
        }
    }

    @include media-breakpoint-up(md) {
        & {
            padding: 0 $o-sm;
        }
    }

    @include media-breakpoint-up(lg) {
        & {
            width: 33.33334%;
        }
    }

    @include media-breakpoint-up(xl) {
        & {
            padding: $g-sm;
        }
    }
}

@mixin font-26 {
    font-size: 1.8rem;

    @include media-breakpoint-up(md) {
        & {
            font-size: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        & {
            font-size: 2.2rem;
        }
    }

    @include media-breakpoint-up(xl) {
        & {
            font-size: 2.4rem;
        }
    }

    @include media-breakpoint-up(hd) {
        & {
            font-size: 2.6rem;
        }
    }
}

@mixin font-64 {
    font-size: 2rem;

    @include media-breakpoint-up(sm) {
        & {
            font-size: 3rem;
        }
    }

    @include media-breakpoint-up(md) {
        & {
            font-size: 4rem;
        }
    }

    @include media-breakpoint-up(xl) {
        & {
            font-size: 5rem;
        }
    }

    @include media-breakpoint-up(hd) {
        & {
            font-size: 6rem;
        }
    }

    @include media-breakpoint-up(laptop) {
        & {
            font-size: 6.4rem;
        }
    }
}

@mixin font-96 {
    font-size: 3.2rem;

    @include media-breakpoint-up(sm) {
        & {
            font-size: 3.4rem;
        }
    }

    @include media-breakpoint-up(md) {
        & {
            font-size: 4.2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        & {
            font-size: 5.2rem;
        }
    }

    @include media-breakpoint-up(xl) {
        & {
            font-size: 6.2rem;
        }
    }

    @include media-breakpoint-up(hd) {
        & {
            font-size: 7.2rem;
        }
    }

    @include media-breakpoint-up(laptop) {
        & {
            font-size: 8.2rem;
        }
    }

    @include media-breakpoint-up(desktop) {
        & {
            font-size: 9.6rem;
        }
    }
}

@mixin font-h1 {
    font-size: 3rem;

    @include media-breakpoint-up(sm) {
        & {
            font-size: 4rem;
        }
    }    

    @include media-breakpoint-up(md) {
        & {
            font-size: 6rem;
        }
    }    

    @include media-breakpoint-up(xl) {
        & {
            font-size: 7.2rem;
        }
    }    

    @include media-breakpoint-up(hd) {
        & {
            font-size: 8rem;
        }
    }    

    @include media-breakpoint-up(laptop) {
        & {
            font-size: 9rem;
        }
    }    
}

@mixin is-ready {
    .is-ready & {
        pointer-events: none;
        color: $grey-color;
        background: rgba(227, 229, 236, 0.5);
    }
}

@mixin input-price {
    display: inline-block;
    
    width: auto;
    max-width: 83px;
    padding: 10px;

    text-align: center;
    font-size: 1.6rem;
    
    border-radius: 0;
}

@mixin hover-arrow-lines {

    .btn-arrow__svg {
        &-line {
            &--1 {
                transform: translate(20%, -73%) rotate(45deg);
            }
            
            &--2 {
                transform: translate(-27%, 95%) rotate(-45deg);
            }
    
            &--3 {
                transform: translateX(-6%);
            }
        }
    }
}

@mixin line-clamp($count: 5) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    font-size: $f-min;
  
    @media (min-width: $w-min) {
        @if ($fallback) {
            font-size: $fallback;
        }
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);  
    }
    @media (min-width: $w-max) {
        font-size: $f-max;
    }
}