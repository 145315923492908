.project {
    &-donate {
        &__info {
            margin-bottom: $o;

            .c-donated {
                max-width: 350px;
                margin-bottom: $g-sm;

                &__price::after {
                    display: none;
                }
            }
        }
        
        &__total {
            text-transform: uppercase;
            font-size: 2rem;
        }

        &__support {
            max-width: 350px;

            .is-ready & {
                display: none;
            }

            .btn {
                width: 100%;
            }

            .share {
                margin: $g-sm 0 0 0;
            }
        }

        &__ready {
            max-width: 350px;
            display: none;
            
            .is-ready & {
                display: block;
            }
            
            &-title {
                display: inline-block;
                text-transform: uppercase;
                font: {
                    size: 2.4rem;
                    weight: 400;
                };
                
                margin-bottom: $o-xs;

                position: relative;

                &::after {
                    content: '';

                    display: inline-block;
                    background: url(../img/svg/ready.svg) center no-repeat {
                        size: contain;
                    };
                    width: $g;
                    height: $o-lg;

                    transform: translateX(7px);
                }
            }
            
            .c-seo {
                font-size: 1.6rem;
            }
        }
    }

    &-img {
        @include margin-out;

        max-height: 670px;
        overflow: hidden;
    }

    &-title {
        margin-top: -6.5em;
        width: 90%;
        min-height: 105px;
        position: relative;
        z-index: 1;

        .h1 {
            font-weight: 700;

            @extend .fz-64;
        }
    }

    &-info {
        margin-top: $o-lg;
        padding-bottom: $o;

        position: relative;

        &::after {
            content: '';

            display: block;

            width: 70%;
            max-width: 400px;
            height: 1px;

            background-color: $grey-color;
        }

        &__tabs {
            position: relative;
        }
    }
}

@include media-breakpoint-up(sm) {

    .project {
        &-title {
            margin-top: -7.5em;
        }
    }

}

@include media-breakpoint-between(sm, md) {

    .project {
        &-donate {
            display: flex;

            &__info,
            &__support,
            &__ready {
                width: 50%;
            }
            
            &__info {
                padding-right: $o-xs;
            }

            &__support,
            &__ready {
                padding-left: $o-xs;
                margin-left: auto;
            }
        }
    }

}

@include media-breakpoint-up(md) {

    .project {
        &-donate {
            &__ready {
                &-title {
                    font-size: 2.8rem;
                }
            }
        }

        &-title {
            margin-top: -10.5em;
            min-height: 147px;

            .h1 {
                // font-size: 5rem;
            }
        }

        &-info {
            padding-bottom: $g;
        }
    }

}

@include media-breakpoint-up(lg) {

    .project {
        &-donate {
            padding: $g-sm 0;
        }

        &-info {
            max-width: 930px;
        }

        &-title {
            min-height: 161px;

            .h1 {
                // font-size: 6.4rem;
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .project {
        &-donate {
            &__info {
                margin-bottom: $o-lg;
            }
            &__ready {
                &-title {
                    font-size: 3rem;
                }
            }
        }
    }

}
