.u-color-hover {
    color: $black;
    text-decoration: none;
    transition: $transition;
    
    &:hover {
        color: $black;
        opacity: .7;
        border-color: transparent !important;
        text-decoration: none;
    }
}