.c-timeline {
    margin-bottom: $g-lg;

    .btn {
        display: block;
        margin: 0 auto;

        font-size: 2rem;
        padding: $o-sm 70px;
    }
    
    &__list {
        padding: $o-lg 0;
        position: relative;

        &::before {
            content: '';

            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;

            width: 1px;
            background-color: #000;
            z-index: -1;
        }
    }

    &__item {
        max-width: 410px;
        
        margin: 0 auto $o;
        padding-bottom: $g;
        
        background-color: $white;
        z-index: 1;

        &:last-child {
            padding-bottom: $o-xs;
        }
        
        &--month {
            padding: $o-xs;
            text-align: center;
            margin: 0 auto $o !important;

            span {
                display: inline-block;
                padding: $g-xs $o-xs;
                background-color: #000;
                color: $white;
            }
        }
    }

    &__price {
        display: inline-block;
        font-size: 2rem;
        margin-bottom: $o-xs;
    }

    &__info {
        border: 1px solid #000;
        padding: $o $o $g;

        .date {
            display: inline-block;
            margin-bottom: $g-xs;
        }

        .c-seo {
            margin-bottom: 0;
            font-size: 1.6rem;
        }
    }

    &__started {
        margin: 0 auto $g * 3;
        text-align: center;

        &-inner {
            display: inline-block;
            padding: $o-lg $o-lg $o-lg $g;
            text-align: left;
            
            background: #000;
            position: relative;

            span {
                color: $white;
                font-size: 2.2rem;

                z-index: 1;
                position: relative;
            }
    
            &::after {
                content: '';
    
                position: absolute;
                right: -$o-xs;
                top: -$g;
    
                background: url(../img/svg/started.svg) center no-repeat {
                    size: contain;
                };
                width: 100px;
                height: 250px;
            }
        }
    }
}

@include media-breakpoint-up(md) {

    .c-timeline {

        .btn {
            font-size: 2.4rem;
        }
        
        &__item {
            width: calc(50% - 60px);
            margin: 0;
            margin-bottom: $o-lg;
            padding: 0;

            &--left {
                margin-right: auto;

                .c-timeline__info {
                    &::before {
                        right: -16px;
                        border-right: inherit;
                        
                        transform: skewX(-45deg);
                    }
                }
            }
            
            &--right {
                margin-left: auto;
                
                .c-timeline__info {
                    &::before {
                        left: -16px;
                        border-left: inherit;

                        transform: skewX(45deg);
                    }
                }
            }

            &--month {
                width: auto;
            }
        }

        &__price {
            font-size: 2.4rem;
        }

        &__info {
            position: relative;

            &::before {
                content: '';

                position: absolute;
                top: $o;

                width: $o;
                height: $o;
                border: {
                    top: inherit;
                };

                background: $white;
            }
        }

        &__started {
            &-inner {
                span {
                    font-size: 2.8rem;
                }
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-timeline {
        &__price {
            font-size: 2.8rem;
            margin-bottom: $o-sm;
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-timeline {
        &__price {
            font-size: 3.2rem;
        }
    }

}

@include media-breakpoint-up(hd) {

    .c-timeline {
        
        &__price {
            font-size: 3.6rem;
        }

        &__started {

            &-inner {
                padding: 90px $o-lg;

                span {
                    font-size: 3.6rem;
                }

                &::after {
                    top: -$g-lg;

                    width: 170px;
                    height: 390px;
                }
            }
        }
    }

}