.custom-container {
    padding: 0 $g-sm;
}

.layout-block {
    margin-bottom: 50px;

    &:last-child {
        & > div,
        & > p {
            margin-bottom: 0;
        }
    }
}

.come-back-link {
    display: inline-block;
    position: relative;
    padding-left: $g;
    margin-bottom: $o-xs;

    @extend .u-color-hover;

    &:hover {

        &::before {
            left: -$o-xs;
        }
    }

    &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;

        background: url(../img/svg/arrow-come-back.svg) center no-repeat;
        width: $o;
        height: $g-sm;

        transform: translateY(-50%);
        transition: $transition;
    }
}

.date {
    color: $grey-color;
    font-size: 1.2rem;
}

.h1 {
    text-transform: uppercase;
    @include font-h1;

    &--lg {
        @include font-96;
        text-transform: uppercase;
    }
}

.h2 {
    margin-bottom: $o;
}

.share {
    font-size: 1.6rem;
    margin: $g 0;

    a {
        color: #000;

        @extend .u-color-hover;
        
        svg {
            fill: #000;
            height: 15px;
        }
    }
}

span.description,
figcaption.description {
    display: inline-block;
    margin: $g-xs 0;
    color: $grey-color;
    font-size: 1.6rem;
}

.lazy {
    filter: blur(5px);

    &.loaded {
        filter: blur(0);
    }
}

@include media-breakpoint-down(md) {
    .c-main {
        &-col {
            margin-bottom: $o;
        }
    }

}

@include media-breakpoint-up(md) {

    .custom-container {
        padding: 0 $o;
    }

    .c-main {
        &-img {
            margin: 0 (-$o);
        }

        &-col {
            &--sm {
                max-width: 770px;
            }
        }
    }

    .h2 {
        font-size: 2.4rem;
    }

}

@include media-breakpoint-up(lg) {

    .custom-container {
        padding: 0 $g;
    }

    .layout-block {
        margin-bottom: 70px;
    }

    .c-row {
        display: flex;
    }

    .c-main {

        &-img {
            margin: 0 0 0 (-$g);
        }

        &-col {
            width: calc(100% - 300px);
            padding-right: $o;
        }
    }

    .c-sidebar {
        width: 300px;
    }

    .h2 {
        font-size: 2.8rem;
    }

}

@include media-breakpoint-up(xl) {

    .custom-container {
        padding: 1px $o-lg 0;
    }
    
    .c-main {
        &-col {
            width: calc(100% - 350px);
            padding-right: $g;
        }
    }

    .c-sidebar {
        width: 350px;
    }

    .h1 {
        &--lg {
            margin-bottom: $o-lg;
        }
    }

    .h2 {
        font-size: 3.2rem;
    }

}

@include media-breakpoint-up(hd) {

    .h2 {
        font-size: 3.6rem;
    }

    .custom-container {
        margin: 0 auto;
        padding: $g-xs $g-lg 0;
        max-width: 1920px;
    }

}