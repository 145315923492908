.o-support {
    position: relative;

    height: calc((var(--vh,1vh)*100) - 60px);

    padding: $o-lg $g-sm;
    @include margin-out;
    
    display: flex;
    align-items: flex-end;

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .c-seo {
        color: $white;
        max-width: 90%;
        padding-bottom: 0;
    }

    &::before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: #4c4c4c;
        z-index: -1;
    }
}

.c-support {
    
    &__price {
        display: inline-block;
        color: $white;
        
        border: 1px solid $white;
        
        margin-right: $o-xs;
        padding: $o-xs $g-sm;
    }
    
    &__action {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        margin-top: $o;

        form {
            display: flex;
        }

        .form-control {
            @include input-price;

            background: transparent;
            color: $white;

            & + label {
                color: $white;
                margin-left: $g-xs;
                font-size: 1.6rem;
                margin-bottom: 0;
            }

            &:focus {
                border-color: $white; 
            }
        }

        .btn {
            margin-left: $o-xs;

            &:hover {
                opacity: 1;
                background-color: rgb(116, 255, 130);
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .c-support {
        &__price {
            margin-right: $g-sm;
        }

        &__action {
            .btn {
                margin-left: $g-sm;
            }
        }
    }

}

@include media-breakpoint-up(md) {
    
    .o-support {
        padding-left: $o-lg;
        padding-right: $o-lg;

        .c-seo {
            max-width: 734px;
        }
    }

    .c-support {
        &__price {
            margin-right: $g;
        }

        &__action {
            margin-top: $g;

            .form-control {
                padding: 12px;
            }
        }
    }

}

@include media-breakpoint-up(lg) {
    
    .o-support {
        margin: 0 (-$g);
        padding: $g-lg;
    }
    
    .c-support {
        &__action {
            margin-top: $o-lg;

            .form-control {
                padding: $o-sm;
            }
        }
    }
    
}

@include media-breakpoint-up(xl) {
    
    .o-support {
        padding-left: $g * 3;
        padding-right: $g * 3;
        margin: 0 (-$o-lg);

        height: calc((var(--vh,1vh)*100) - 66px);
    }
    
}

@include media-breakpoint-up(hd) {
    
    .o-support {
        margin: 0 (-$g-lg);
        height: calc((var(--vh,1vh)*100) - 70px);
        padding-left: $g * 4;
        padding-right: $g * 4;
    }

}