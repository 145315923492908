.tabs {
    .ps {
        &__rail-x {
            max-height: 3px !important;
            bottom: 1px !important;
        }
        
        &__thumb-x {
            max-height: 5px !important;
            bottom: -1px !important;
        }
    }
}