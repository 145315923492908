.c-video {
    max-width: 1200px;
    margin-bottom: $o;
    
    &__inner {
        position: relative;
    }

    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .init-player & {
            display: none;
        }
    }

    .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        transition: .2s;
        z-index: 1;

        &.init-player,
        &.init-player + div {
            display: none;
        }

        &:hover {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}