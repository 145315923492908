.swiper-pagination-bullet {
    width: 12px;
    height: 12px;

    background: transparent;
    
    border: 1px solid $white;
    opacity: 1;

    &-active {
        position: relative;

        &::before {
            content: '';

            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            width: 6px;
            height: 6px;

            display: block;
            background-color: $white;

            border-radius: 50%;
        }
    }
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    position: relative;
    max-width: 350px;
    padding: 0 $o-lg;
    margin: 0 auto $g;
    
    .page {
        
        &-item {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 34px;
            height: 34px;

            
            margin: 0 2px;
            
            &.active {
                background: #000;
                border-radius: 0;

                .page-link {
                    background-color: #000;
                    color: $white;

                    &:hover {
                        color: $white;
                        opacity: 1;
                    }
                }
            }
        }

        &-link {
            display: inline-block;
        
            border: none;
            margin: 0;
            background-color: transparent;
            
            font-size: 1.6rem;
            color: #000;
            padding: 6px 12px;
            
            @extend .u-color-hover;
            
            &:hover {
                background-color: transparent;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
            
            .btn-arrow__svg--lg {
                height: $g-sm;
                overflow: visible;
                will-change: transform;
            }
        }

        &-arrow {

            &:hover {
                @include hover-arrow-lines;
            }

            &.prev,
            &.next {
                position: absolute;
                top: 50%;
                cursor: pointer;

                &:hover .page-link {
                    transform: translateX($o-xs);
                }
            }
            &.prev {
                left: -$o-xs;
                transform: translateY(-50%) rotate(180deg);
            }
            
            &.next {
                transform: translateY(-50%);
                right: -$o-xs;
            }

            &.disabled {
                pointer-events: none;

                .btn-arrow__svg--lg {
                    fill: $grey-color;
                }
            }

            .page-link {
                width: auto;
                padding: $o-xs;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .pagination {
        margin-bottom: 70px;
    }

}

@include media-breakpoint-up(xl) {

    .pagination {
        .page {
            &-link {
                .btn-arrow__svg--lg {
                    height: $o;
                    width: $o-lg;
                }
            }
        }
    }

}