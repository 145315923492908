.c-file {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $g;

    &__format {
        width: $g;
        height: $g;

        background-color: #000;
        color: $white;
        text-transform: uppercase;

        text-align: center;
        line-height: $g;
        font: {
            size: 1.2rem;
            weight: 700;
        }

        &.is-other {
            font-size: 1rem;
        }
    }

    &__name {
        display: block;
        max-width: 260px;
        @extend .u-color-hover;
        
        font: {
            size: 1.6rem;
            weight: 700;
        };
        margin-bottom: $g-xs;
        
        span {
            border-bottom: 1px dotted #000;
            box-decoration-break: clone;
            word-wrap: break-word;
        }
    }

    &__info {
        padding-left: $o-xs;
        transform: translateY(-6px);
        width: calc(100% - 40px);

        .format {
            text-transform: uppercase;
        }

        .size,
        .format {
            color: $grey-color;
            font-size: 1.6rem;
        }
    }

    &__description {
        max-width: 500px;
        font-size: 1.6rem;
        margin-top: $o-xs;
    }
}