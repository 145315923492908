.c-accordion {
    max-width: 700px;
    
    &:not(:last-child) {
        border-bottom: 1px solid $grey-color;
    }

    &:last-child {
        margin-bottom: $o;
    }

    &__title {
        @include font-26;

        font: {
            weight: 400;
        }
        margin-bottom: 0;
    }
    
    &__link {
        @extend .u-color-hover;

        display: block;
        padding: $o-xs 0;
        padding-right: $o;

        position: relative;

        &::after {
            content: '';
            
            position: absolute;
            right: 0;
            top: 50%;
    
            // transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%);

            background: url(../img/svg/arrow-down.svg) center no-repeat {
                size: contain;
            }
            width: $g-sm;
            height: 8px;
        }

        // &.collapsed {
        //     &::after {
        //         transform: translateY(-50%) rotate(0deg);
        //     }
        // }
    }

    &__content {
        padding-bottom: $o-sm;
        font-size: 1.4rem;
    }
}

@include media-breakpoint-up(md) {

    .c-accordion {

        &:last-child {
            margin-bottom: $g;
        }

        &__link {
            padding: $o-sm 0;
            padding-right: $g;
            
            &::after {
                height: $o-xs;
            }
        }

        &__content {
            font-size: 1.6rem;
            padding-bottom: $g-sm;
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-accordion {

        &__link {
            padding: $g-sm 0;
            padding-right: $g;

            &::after {
                height: 12px;
            }
        }

        &__content {
            padding-bottom: $o;
        }
    }

}