.c-footer {
    padding-bottom: $o-xs;

    .c-ideil {
        font-size: 1.6rem;
        padding-bottom: $o;

        &-link {
            display: inline-block;
            padding: $o-xs;
            color: #000;
            margin-left: -$o-xs;

            @extend .u-color-hover;
        }

        &-img {
            margin-top: -5px;
            margin-left: -12px;
            height: 15px;

        }
    }

    &-inner {
        display: flex;
        flex-direction: column;

        border-top: 1px solid #000;

    }

    &-menu {
        padding: $o 0;
    }

    &-info {
        order: 2;
        font-size: 1.6rem;
        border-bottom: 1px solid $grey-color;
        padding: $o 0;

        p {
            margin: 0;
        }

        .c-social {
            margin: $g-xs 0 $o (-$o-sm);
            padding: 0;
        }
    }

    &-feedback {
        font-size: 1.4rem;
        padding: $o 0 25px;
        border-top: 1px solid $grey-color;
        border-bottom: 1px solid $grey-color;

        &__mail,
        &__phone {
            display: inline-block;
            margin-bottom: $g-xs;

            font: {
                weight: 600;
                size: 1.6rem;
            };
            color: #000;
            text-decoration: none;

            @extend .u-color-hover;
        }
    }
    
    @include media-breakpoint-up(sm) {
        &-inner {
            flex-direction: row;
            flex-wrap: wrap;
        }
        &-menu {
            width: 100%;
            border-bottom: 1px solid $grey-color;
        }

        &-info {
            width: 50%;
            border-bottom: none;
        }


        &-feedback {
            width: 50%;
            order: 2;
            border: none;
        }
    }
    
    @include media-breakpoint-up(md) {
        &-inner {
            flex-direction: row;
        }

        &-info {
            .c-social {
                text-align: left;
                margin-left: -$o-sm;
            }
        }

        &-feedback {
            &__address {
                margin-bottom: $o-sm;
            }
        }
    
    }
    
    @include media-breakpoint-up(lg) {
        &-info {
            .c-social-link img {
                height: $g-sm;
                width: 25px;
            }
        }

        &-menu {
            width: 26%;
            border: none;
        }

        &-feedback {
            width: 37%;
            border: none;

            display: flex;
            justify-content: flex-end;
        }

        &-info {
            width: 37%;
            border: none;
            order: 0;
        }

        .c-ideil {
            text-align: center;
        }
    
    }
    
    @include media-breakpoint-up(xl) {
        &-menu {
            width: 50%;

            .c-nav {
                &-item {
                    width: 50%;
                    text-align: left;

                    &:nth-child(4) {
                        order: 4;
                    }

                    &--btn {
                        transform: translateX(25px)
                    }
                }

                &-link {
                    white-space: nowrap;
                }
            }
        }

        &-feedback {
            width: 25%;
            font-size: 1.6rem;

            &__address {
                margin-bottom: $g-sm;
            }
        }

        &-info {
            width: 25%;
            
            .c-social {
                margin-top: $o-sm;
            }
        }
    
    }
    
    @include media-breakpoint-up(laptop) {
        &-menu {
            width: 60%;
            .c-nav {

                &-item {
                    width: 33.33334%;

                    &:nth-child(3) {
                        order: 5;
                    }

                    &:nth-child(4) {
                        order: 4;
                    }

                    &--btn {
                        order: 3;
                        transform: translateX(0);
                    }
                }
            }
        }

        &-info,
        &-feedback {
            width: 20%;
        }
    }

    @include media-breakpoint-only(lg) {
    
        &-info {
            .c-social {
                margin-top: $o-sm;
                margin-bottom: 25px;
            }
        }

        &-feedback__address {
            margin-bottom: 23px;
        }
    
    }

    @include media-breakpoint-down(md) {
        &-menu {
           padding: 13px 0;

           .c-nav-list {
                align-items: center;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        &-menu {
            padding: $g-sm 0 $o;
        }
    }

}
