.about {
    &-top {
        position: relative;

        &::after {
            content: '';

            position: absolute;
            right: 5%;
            top: -5%;
            height: 60%;
            width: 50%;

            background: url(../img/svg/happy.svg) right no-repeat {
                size: contain;
            };

            z-index: -1;

        }

    }

    .h2 {
        text-transform: uppercase;
        @include font-64;

        margin: 0 0 $o-lg;
    }
    
    &-info {
        &__courage {
            
            .c-seo {
                font-size: 1.8rem;
            }

            img {
                width: $o-lg;
            }
        }

        margin-bottom: $o-lg;
    }

    &-benefit {
        
        &__block {
            max-width: 420px;
            margin-bottom: $o-lg;

            &--lg {
                max-width: 550px;
            }
            .c-seo {
                img {
                    width: 40px;
                }
            }
        }

        &__img {
            @extend .u-color-hover;
        }
    }
}

@include media-breakpoint-up(sm)  {

    .about {

        &-top {
            &::after {
                height: 100%;
                width: 100%;
            }
        }

        &-benefit {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            &__block {
                width: calc(50% - 15px);
                
                &--lg {
                    width: calc(60% - 15px);
                    
                    & + section {
                        width: calc(40% - 15px);
                    }
                }
                
                &:nth-child(even) {
                    margin-left: auto;
                }
            }
        }
    }

}

@include media-breakpoint-up(md)  {

    .about {
        &-info {
            &__courage {
            
                .c-seo {
                    font-size: 2.2rem;
                }
            }
        }

        &-benefit {
            &__block {
                
                &:nth-child(even) {
                    width: calc(50% - 60px);
                    margin-left: $g;
                }

                &--lg {
                    & + section {
                        width: calc(40% - 40px) !important;
                        margin-left: $g;
                    }
                }
                
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .about {
        &-top {
            .c-main-col {
                z-index: 1;
                padding-right: 0;
            }
    
            .icon {
                display: block;
                transform: translate(-70%, -10%);
    
                svg {
                    max-width: 100%;
                    width: 100%;
                    z-index: -1;
                }
            }
        }

        &-info {
            display: flex;
            
            &__courage {
                flex-shrink: 0;
                padding-left: $o-lg;
                
                .c-seo {
                    width: 100%;
                    font-size: 2.4rem;
                }
            }
        }

        &-benefit {

            &__block {
                &:nth-child(even) {
                    width: calc(50% - 80px);
                    margin-left: $g-lg;
                }

                &--lg {
                    & + section {
                        width: calc(40% - 80px) !important;
                        margin-left: $g-lg;
                    }
                }
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .about {

        .h2 {
            margin: $g-lg 0 ($o * 4);
        }

        &-info {
            max-width: 1200px;

            &__courage {
                padding-left: $g-lg;

                .c-seo {
                    font-size: 2.8rem;
                }
            }
        }

        &-benefit {
            &__block {
                &:nth-child(even) {
                    margin-left: 15%;
                }
            }
        }
    }

}

@include media-breakpoint-up(hd) {

    .about {
        &-top {
            &::after {
                right: 15%;
            }
        }

        &-info {
            
            &__courage {
                .c-seo {
                    font-size: 3.2rem;
                }
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .about {

        .h2 {
            margin: ($g * 3) 0 ($g * 4);
        }

        &-info {
            &__courage {
                .c-seo {
                    font-size: 3.6rem;
                }
            }
        }
    }

}
