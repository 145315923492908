.c-social {
    &-item {
        display: inline-block;
        margin: 0 $o-xs;
    }
    
    &-link {
        padding: $g-xs;

        img {
            transition: $transition;
            will-change: opacity;
        }

        &:hover {
            img {
                opacity: .7;
            }
        }
    }

    // < 576
    @include media-breakpoint-down(xs) {
    
        padding: $o-xs 0;
    
        .c-banner & {
            padding: $o-sm 0;
        }
    
    }
    
    // > 576 < 1200
    @include media-breakpoint-between(sm, xl) {
        padding: $g-sm 0 25px;
    }
    
    
    // < 992
    @include media-breakpoint-down(lg){
        margin: 0 auto;

        &-link {
            svg {
                height: 18px;
            }
        }
        
        .c-banner & {
            border-top: 1px solid $grey-color;
            border-bottom: 1px solid $grey-color;
            width: 80%;
            max-width: 250px;
            margin-bottom:  $g-sm;
        }
    }
    
    // < 1200
    @include media-breakpoint-up(xl) {
    
        padding: 7px 0;
        margin-right: $o-xs;

        &-list {
            display: flex;
        }

        &-link {
            display: inline;
            
            img {
                height: $o-sm;
            }
        }
    
    }
}
