.btn {
    background: transparent;
    padding: $o-xs $g;
    font-size: 1.6rem;
    border-radius: 0;
    box-shadow: none;
    
    &:active,
    &:focus {
        box-shadow: none;
    }

    &--green {
        background-color: $green;

        &:hover {
            opacity: .8;
        }
    }

    &-arrow {
        position: relative;
        
        width: 40px;
        height: 28px;

        cursor: pointer;
        z-index: 1;

        &.prev {
            transform: rotate(180deg);
            left: $g-sm;
        }

        &-line {
            &--1,
            &--2,
            &--3 {
                display: inline-block;
                height: 3px;
                background-color: $white;

                position: absolute;
                will-change: transform;
                transition: $transition;
            }

            &--1 {
                width: 16px;
                left: 22px;
                top: 20px;
                transform: rotate(-45deg);
            }
            
            &--2 {
                width: 31px;
                top: 50%;
                transform: translateY(-50%);
            }

            &--3 {
                width: 20px;
                left: 22px; 
                bottom: 19px;
                transform: rotate(45deg);
            }
        }

        &:hover {
            .btn-arrow-line {
                &--1 {
                    top: 22px;
                }
                
                &--2 {
                    transform: translateY(-50%) translateX(-8%);
                }
    
                &--3 {
                    bottom: 21px;                
                }
            }
        }

        &.swiper-button-disabled {
            opacity: .3;
            cursor: default;

            &:hover {
                .btn-arrow-line {
                    &--1 {
                        top: 20px;
                    }
                    
                    &--2 {
                        transform: translateY(-50%);
                    }
        
                    &--3 {
                        bottom: 19px;
                    }
                }
            }
        }

        &.next {
            right: $g-sm;
        }
    }

    &-play {
        background-color: transparent;
        border: none;

        cursor: pointer;

        &__svg {
            width: 50px;
        }
    }
}

@include media-breakpoint-up(sm) {

    .btn {
        &-arrow {
            width: 70px;
            height: 41px;

            &-line {
                &--1,
                &--2,
                &--3 {
                    height: 5px;
                }

                &--1 {
                    width: 24px;
                    left: 34px;
                    top: 29px;
                }
                
                &--2 {
                    width: 46px;
                }
    
                &--3 {
                    width: 29px;
                    left: 34px;
                    bottom: 27px;
                }
            }
            
            &:hover {
                .btn-arrow-line {
                    &--1 {
                        top: 31px;
                    }
        
                    &--3 {
                        bottom: 30px;                
                    }
                }
            }

            &.swiper-button-disabled {
                &:hover {
                    .btn-arrow-line {
                        &--1 {
                            top: 29px;
                        }
            
                        &--3 {
                            bottom: 27px;
                        }
                    }
                }
            }

        }
    }

} 

@include media-breakpoint-up(md) {
    .btn {
        font-size: 2rem;

        &-play {
            &__svg {
                width: $o-lg;
            }
        }

        &-arrow {
            width: 80px;
            height: 55px;

            &.prev {
                left: $o;
            }

            &.next {
                right: $o;
            }

            &-line {
                &--1,
                &--2,
                &--3 {
                    height: 6px;
                }

                &--1 {
                    width: 31px;
                    left: 45px;
                    top: 39px;
                }
                
                &--2 {
                    width: 62px;
                }
    
                &--3 {
                    width: 39px;
                    left: 44px;
                    bottom: 37px;
                }
            }
            
            &:hover {
                .btn-arrow-line {
                    &--1 {
                        top: 43px;
                    }
        
                    &--3 {
                        bottom: 40px;                
                    }
                }
            }

            &.swiper-button-disabled {
                &:hover {
                    .btn-arrow-line {
                        &--1 {
                            top: 39px;
                        }
            
                        &--3 {
                            bottom: 37px;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .btn {
        font-size: 2.4rem;

        &-arrow {
            &.prev {
                left: $g;
            }

            &.next {
                right: $g;
            }
        }
        
        
        &-play {
            &__svg {
                width: $g-lg;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .btn {
        &-play {
            &__svg {
                width: 113px;
            }
        }

        &-arrow {
            width: 100px;
            height: 69px;

            &.prev {
                left: $o-lg;
            }

            &.next {
                right: $o-lg;
            }

            &-line {
                &--1,
                &--2,
                &--3 {
                    height: 7px;
                }

                &--1 {
                    width: 38px;
                    left: 57px;
                    top: 49px;
                }
                
                &--2 {
                    width: 77px;
                }
    
                &--3 {
                    width: 49px;
                    left: 55px;
                    bottom: 46px;
                }
            }
            
            &:hover {
                .btn-arrow-line {
                    &--1 {
                        top: 54px;
                    }
        
                    &--3 {
                        bottom: 51px;                
                    }
                }
            }

            &.swiper-button-disabled {
                &:hover {
                    .btn-arrow-line {
                        &--1 {
                            top: 49px;
                        }
            
                        &--3 {
                            bottom: 46px;
                        }
                    }
                }
            }
        }
    }
}