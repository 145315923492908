*:focus {
    outline: none !important;
}

html {
    font-size: 10px;
}

body {
    font-size: 1.4rem;
    background-color: $body-bg;
    fill: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Formular', Arial;
    color: #000;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: calc(var(--vh,1vh)*100);
}

main {
    padding-top: $o-lg;
    flex-grow: 1;

    &.backdown {
        padding-top: $g-lg;
    }

    &.main-error {
        padding: 0;
    }

}

img {
    max-width: 100%;
    height: auto;
}

svg {
    transition: $transition; 
}

button,
.btn,
a {
    transition:
        color $transition,
        background $transition,
        border-color $transition,
        $transition,
        opacity $transition;
    outline: none !important;
}

a {
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
    }
}

textarea {
    resize: vertical;
}

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}

.list, ul, ol {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}


// < 992
@include media-breakpoint-down(lg) {
    
    html {
        &.show-menu {
            overflow: hidden;
        }
    }
}
// < 992
@include media-breakpoint-up(xl) {

    main {
        padding-top: 65px;

        &.backdown {
            padding-top: 175px;
        }
    }

}
