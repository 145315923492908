.error {
    position: relative;
    
    height: 99vh;
    
    display: flex;
    align-items: center;
    
    background: {
        position: center right;
        repeat: no-repeat;
        size: contain;
    };

    &-info {
        max-width: 80%;
    }
}

@include media-breakpoint-up(md) {
    .error {
        &-info {
            .c-seo {
                max-width: 80%;
            }
        } 
    }
}