.c-nav {
    will-change: transform;
    
    &-item {
        display: block;
    }

    &-link {
        display: inline-block;
        text-decoration: none;
        font-size: 1.6rem;
        color: #000;
        padding: 12px $g-sm;

        &:hover {
            text-decoration: none;
            opacity: .5;
            color: #000;
        }

        &.is-active {
            opacity: .5;
        }
    }
}

// < 992
@include media-breakpoint-down(lg) {

    .c-banner {
        .c-nav {
            margin-bottom: $g;
    
            &-item {
                &--btn {
                    margin-top: $o-sm;
                }
            }

            &-link {
                font-size: 2.2rem;
            }
        }
    }

    .c-footer {
        .c-nav {
            transform: translateX(-$g-sm);

            &-item {

                &--btn {
                    margin-left: $g-sm;
                    margin-top: $g-sm;
                }
            }
        }
    }
}

// < 768
@include media-breakpoint-down(sm) {
    
    .c-banner {
        .c-nav {
            &-link {
                font-size: 2rem;
            }
        }
    }
}

// < 576
@include media-breakpoint-down(xs) {
    .c-nav {
        
        &-item {
            &:not(:last-child) {
                margin-bottom: $g-xs;
            }
        }
        
        &-link {
            font-size: 1.6rem;
            padding: $o-xs $g-sm;
        }
    }
    
    .c-banner {
        .c-nav {
            margin-bottom: $g-sm;
        }
    }

}

@include media-breakpoint-between(lg, xl) {

    .c-nav {
        &-link {
            padding: $o-xs $o-sm;
            font-size: 1.6rem;
        }
    }
}

@include media-breakpoint-up(sm) {

    .c-footer {
        .c-nav {
            &-list {
                display: flex;
                flex-wrap: wrap;
            }

            &-item {
                margin: $g-xs 0;
                
                &:nth-last-child(2) {
                    margin-right: $o-xs;
                }

                &--btn {
                    margin: 17px 0 17px $g-sm;
                }

            }
        }
    }

}

@include media-breakpoint-only(lg) {

    .c-footer {
        .c-nav {
            &-list {
                flex-direction: column;
            }
        }
    }

}

@include media-breakpoint-up(lg)  {

    .c-footer {
        .c-nav {
            transform: translate(0, -10px);

            &-item {
                margin: 0;

                &:nth-last-child(2) {
                    margin-right: 0;
                }

                &--btn {
                    margin-left: $o-sm;
                    margin-top: $o-sm;
                }
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-banner {
        .c-nav {
            margin-left: 130px;
            margin-right: auto;
            
            &-item {
                display: inline-block;

                &--btn {
                    margin-left: $g-sm;
                }
            }
    
            &-link {
                display: block;
            }
        }
    }
    
    .c-footer {
        .c-nav {
            &-item--btn {
                margin-top: 0;
                margin-left: -$o-xs;
            }

            &-link {
                padding: $o-xs $o-sm;
            }
        }
    }

}

@include media-breakpoint-up(hd) {
    .c-banner {
        .c-nav {
            margin-left: 130px;
        }
    }
}
