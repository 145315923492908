.home {

    &-slider {
        margin-bottom: $o;
        @include margin-out;

        .swiper {
            &-slide {
                max-height: 630px;
                min-height: 220px;
    
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: blur(10px);
                    
                    &.loaded {
                        filter: none;
                    }
                }
    
                .h1 {
                    display: inline-block;
                    position: absolute;
                    bottom: $g;
                    left: $g-sm;
        
                    @extend .fz-64;
                    line-height: 1.3;
        
                    max-width: 85%;
                    margin: 0;
                    transition: $transition;
                    
                    b {
                        @include line-clamp(3);
                    }
                }
                
                &-active .h1 {
                    transform: scaleY(1);
                    opacity: 1;
                }
        
                a {
                    color: #000;

                    &:hover .h1 {
                        color: $primary;
                    }
                }
            }
        }

        .swiper-pagination-bullets {
            width: auto;
            position: absolute;
            bottom: $o-sm !important;
            left: $g-sm !important;
            z-index: 1;
        }

        .btn-arrow {
            position: absolute;
            
            &-line {
                &--1,
                &--2,
                &--3 {
                    background-color: #000;
                }
            }

            &.prev {
                top: 28%;
            }

            &.next {
                top: 28%;
            }
        }
    }

    .brands {
        max-width: 400px;
        margin: 0 auto $g;
        
        &-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 (-$o-xs);

            a {
                width: 33.333334%;
                padding: 0 $o-xs;
                text-align: center;

                img {
                    max-width: 85px;
                    max-height: 80px;
                }

                &:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }

    .weather {
        max-width: 400px;
        margin: 0 auto $o-lg;
    }

}

@include media-breakpoint-only(sm) {
    .home-slider .swiper-slide {
        min-height: 360px;
    }
}

@include media-breakpoint-only(md) {

    .home {

        &-slider .swiper-slide {
            min-height: 490px;
        }

        .c-sidebar {
            display: flex;
            flex-direction: row;
        }

        &-sidebar__top {
            width: 55%;
            padding-right: $o;
        }

        .last-donate {
            width: 44%;
        }
    }

}

@include media-breakpoint-up(md) {

    .home {
        &-slider {
            margin-bottom: $g;
            
            .swiper-slide {
                min-height: 550px;

                .h1 {
                    bottom: 72px;
                    left: $o;
                }
            }

            .swiper-pagination-bullets {
                bottom: $o !important;
                left: $o !important;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .home {
        &-slider {
            margin-bottom: $o-lg;
            
            .swiper-slide {
                min-height: 420px;

                .h1 {
                    left: $g;
                }
            }

            .swiper-pagination-bullets {
                left: $g !important;
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .home {
        &-slider {
            .swiper-slide  {
                min-height: 570px;

                .h1 {
                    left: $o-lg;
                }

            }
            .swiper-pagination-bullets {
                left: $o-lg !important;
            }

            .btn-arrow {
                &.prev {
                    left: $g-sm;
                }
                &.next {
                    right: $g-sm;
                }
            }
        }
    }

}

@include media-breakpoint-up(hd) {
    
    .home {
        &-slider {
            .swiper-slide {
                .h1 {
                    left: $g-lg;
                    max-width: 90%;
                }

            }
            .swiper-pagination-bullets {
                left: $g-lg !important;
            }
        }
    }
    
}

@include media-breakpoint-up(laptop) {
    .home {
        &-slider .swiper-slide {
            min-height: 670px;
        }
    }
}