.c-donated {
    margin-top: auto;

    &__percentages {
        display: inline-block;
        font-size: 1.6rem;
        margin-bottom: $o-xs;
    }

    &__scale {
        background-color: $green;
        height: inherit;
        max-width: 100%;

        &-wrap {
            background-color: #E3E5EC;
            height: 8px;
            margin-bottom: $o-sm;
        }
    }

    &__price {
        font: {
            size: 2rem;
        };
        line-height: 1;

        &.is-collected {
            display: inline-block;
            padding-right: $o-lg;

            position: relative;

            &::after {
                content: '';

                position: absolute;
                right: 0;
                bottom: -$o-xs;

                display: inline-block;
                background: url(../img/svg/ready.svg) center no-repeat {
                    size: contain;
                };
                width: $o-lg;
                height: $o-lg;
            }

            & + span {
                display: block;
            }
        }
    }

    &__collect {
        color: $grey-color;
    }
}

@include media-breakpoint-up(md) {

    .c-donated {
        &__price {
            font-size: 2.4rem;
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-donated {
        &__price {
            font-size: 3rem;
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-donated {
        &__price {
            &.is-collected {
                padding-right: 100px;

                &::after {
                    bottom: -$o;

                    width: 90px;
                    height: 100px;
                }
            }
        }
    }

}