.contacts {
    &-map {
        height: 60vh;
        max-height: 740px;

        &__wrap {
            position: relative;
        }

        &__info {
            position: absolute;
            bottom: 50px;
            left: 0;
            right: 0; 

            pointer-events: none;

            & > * {
                font-size: 2rem;
            }
        }
    }

    &-mail {
        color: #000;
        pointer-events: all !important;

        @extend .u-color-hover;
    }

    &-address {
        margin-bottom: $g-sm;
        line-height: 1.3;
        color: #000;
    }

    &-scheme {
        .h2 {
            text-transform: uppercase;
        }

        &__row {
            font-size: 1.8rem;
            margin-bottom: $g-sm;
        }

        &__item {
            margin-bottom: $g-xs;
        }
    }

    &-sponsors {

        &__item {
           display: flex;
           flex-direction: column;

           margin-bottom: $o;

           & > * {
               font-size: 1.8rem;
               color: #000;
           }
        }

        &__title {
            text-transform: uppercase;
            margin-bottom: $o-xs;
        }

        &__phone {
            margin-bottom: auto;
        }
        
        &__name {
            font-weight: 500;
        }
        
        &__mail {
            margin-top: $o-xs;
        }

        &__phone,
        &__mail {
            @extend .u-color-hover;
        }
    }
}

@include media-breakpoint-up(sm) {

    .contacts {
        &-sponsors {

            &__list {
                display: flex;
                flex-wrap: wrap;
            }

            &__item {
                width: 50%;
            }
        }
    }

}

@include media-breakpoint-up(md) {

    .contacts {

        &-map {
            height: 80vh;

            &__info {
                & > * {
                    font-size: 2.8rem;
                }
            }
        }

        &-address {
            margin-bottom: $o;
        }

        &-sponsors {

            &__item {
                width: 33.333334%;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .contacts {
        &-map {
            height: 100vh;
            height: calc(var(--vh,1vh)*100);

            &__info {
                bottom: 70px;

                & > * {
                    font-size: 3.4rem;
                }
            }
        }

        &-scheme {
            &__item {
                display: inline-block;
                margin-bottom: 0;
            }
        }

        &-sponsors {

            &__item {
                width: 25%;
                max-width: 250px;
            }
        }
    }

}


@include media-breakpoint-up(laptop) {

    .contacts {
        &-map {
            &__info {
                & > * {
                    font-size: 4rem;
                }
            }
        }
    }

}