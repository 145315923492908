.modal {
    will-change: transform;
    transition: .1s;
    
    // transform
    &.fade &-dialog {
        will-change: transform;
        transform: translate(0,0) scale(.9);
    }
    
    &.fade.show &-dialog {
        transform: translate(0,0) scale(1);
    }

    &-dialog {
        max-width: 450px;
    }

    &-close {
        position: absolute;
        top: $g-sm;
        right: $g-sm;

        padding: $o-xs;
        cursor: pointer;
        pointer-events: auto;

        background: transparent;
        border: none;
        
        z-index: 1;

        &__svg {
            fill: #000;
            pointer-events: none;
        }

        &:hover {
            .modal-close__svg {
                fill: $green;
            }
        }
    }

    &-icon {
        width: $g-lg;
        margin-left: -2%;
        margin-bottom: $g-sm;
    }

    &-subtitle {
        @include font-26;

        display: inline-block;
        margin-bottom: $g-sm;
    }

    &-content {
        padding: $g $g-sm;
        display: block;

        .c-donate {

            &__item {
                width: 100%;
                margin: 0 auto $o;
            }
        }

        &__support {
            
            .modal-close {
                background: #fff6;
                
                &__svg {
                    fill: $green;
                }
                
                &:hover {
                    background: #fff8;
                }
            }

            .o-support {
                height: 100vh;
                height: calc(var(--vh,1vh)*100);
                margin-left: 0;
                margin-right: -$o-sm;
            }
        }
    }

    .come-back-link {
        margin-top: $g-sm;
    }

    &-form__direction {
        display: inline-block;
        margin: $g-xs 0 $g-sm;
    }

    //thanks
    &-thanks {
        &__title {
            text-transform: uppercase;
            
            @include font-64;

            margin-bottom: $g-sm;
        }

        &__mail {
            @extend .u-color-hover;
            font-size: 1.6rem;
        }
        
        &__name {
            font-size: 1.6rem;
        }

        .c-seo {
            max-width: 500px;
            margin-bottom: 0;
        }
    }

}

@include media-breakpoint-up(sm) {
    
    .modal {
        &-content {
            padding: $o;
            padding-top: $g;
        }
    }
    
}

@include media-breakpoint-down(sm) {
    .modal-content__support {
        .o-support {
            padding-left: $g-sm;
            padding-right: $g-sm;
        }
    }
}

@include media-breakpoint-up(md) {

    .modal {
        &-dialog {
            max-width: 900px;
        }

        &-icon {
            width: 100px;
        }

        &-content {
            padding: $g $o;

            &__inner {
                max-width: 690px;
                margin: 0 auto;
            }

            .c-donate {
                margin: 0 (-$o-sm) $g-sm;

                &__list {
                    flex-direction: row;
                }

                &__item {
                    width: calc(50% - 30px);
                }
            }
        }
    }

}

@include media-breakpoint-up(lg)  {

    .modal {

        &-content {
            padding: $g $o-lg $g-lg;
        }

        &-icon {
            width: 130px;
            margin-left: -5%;
            margin-bottom: $o;
        }

        &-subtitle {
            margin-bottom: $g;
        }
    }

}

@include media-breakpoint-up(hd)  {

    .modal {
        &-dialog {
            max-width: 1110px;
        }

        &-content {
            padding-top: $o-lg;
        }

        &-icon {
            width: 180px;
        }
    }

}